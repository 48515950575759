
<template>
  <div class="container mt-3 printable">
    <b-row>
        <div class="col-sm-2">
            <img src="../assets/img/TwinLogoBlack512x512.png" class="logo">
            <b-button class="d-print-none" variant="outline-success" @click="printSetup"><font-awesome-icon icon="fa-solid fa-print" /> Print</b-button>
        </div>
      <div class="col-sm-4">
        Date: {{setup.date}} | Contract: {{job.contractId}}
        <b>Material:</b> {{setup.manufacturer}} {{setup.material}}
        <br><b>Color:</b> {{setup.color}}
      </div>
      <div class="col-sm-6 text-right">
        
        <span class="title">Job Name:</span><br> 
        <span class="jobName">{{job.customer.fname}} {{job.customer.lname}}</span><br>
        <span class="title">Jobsite:</span><br>
            <b>{{job.siteAddress.street}},</b> <b>{{job.siteAddress.city}}, {{job.siteAddress.state}} {{job.siteAddress.zip}}</b>
      </div>
    </b-row>
    <b-row>
        <b-col cols="12" class="mt-3">
            <div class="instructions">Instructions:</div>
            <div v-html="setup.instructions" class="instructions-text"></div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="5">
        <div class="section">Materials:</div>
        <b-list-group>
            <b-list-group-item variant="primary" class="material-item">
                <b-row>
                    <b-col cols="2"><b>QTY</b></b-col>
                    <b-col cols="10"><b>Description</b></b-col>
                </b-row>
            </b-list-group-item>
            <b-list-group-item class="material-item" v-for="mat in setup.materials" :key="mat._id">
                <b-row>
                    <b-col cols="2">{{mat.qty}}</b-col>
                    <b-col cols="10">{{mat.desc}}</b-col>
                </b-row>
            </b-list-group-item>
        </b-list-group> 
      </b-col>
      <b-col cols="7">
        <div class="section">Labor:</div>
        <b-list-group>
            <b-list-group-item variant="success" class="material-item">
                <b-row>
                    <b-col cols="2"><b>QTY</b></b-col>
                    <b-col cols="6"><b>Description</b></b-col>
                    <b-col cols="2"><b>Unit</b></b-col>
                    <b-col cols="2"><b>Total</b></b-col>
                </b-row>
            </b-list-group-item>
            <b-list-group-item class="material-item" v-for="labor in setup.labor" :key="labor._id">
                <b-row>
                    <b-col cols="2">{{labor.qty}}</b-col>
                    <b-col cols="6">{{labor.desc}}</b-col>
                    <b-col cols="2" class="text-right">{{labor.unit}}</b-col>
                    <b-col cols="2" class="text-right">{{labor.total}}</b-col>
                </b-row>
            </b-list-group-item>
            <b-list-group-item class="material-item">
                <b-row>
                    <b-col cols="8"><b>Total</b></b-col>
                    <b-col cols="4" class="text-right"><b>${{totalLabor.value()}}</b></b-col>
                </b-row>
            </b-list-group-item>
        </b-list-group> 
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { format, fromUnixTime, getUnixTime } from 'date-fns'
import numbro from 'numbro'


export default {
  name: "printJobSetup",
  components: {
    
  },
  data() {
    return {
      numbro,
      totalLabor: 0,
      setup: {
        instructions: "",
        materials: [],
        labor: [],
      },
      job: {
        customer: {
          fname: "",
          lname: ""
        },
        siteAddress: {
          street: "",
          city: "",
          state: "",
          zip: "" 
        }
      }
    }
  },
  computed: {
        userInfo () {
          return this.$state.userInfo
        },
        id () {
          return this.$route.params.id
        }
  },
  methods: {
    printSetup() {
        window.print()
            
        let log = {
            logTime: getUnixTime(new Date()),
                    logMsg: `Jobsetup was Printed.`,
                    job: this.id,
                    logUser: this.userInfo.name,
        }
        //logit        
        this.$actions.log(log)
    },
    async getSetupInfo(){
      console.log(`Get setup info called.`)

      try {
          let setup = await this.$axios.get(`/jobsetup/${this.id}`, {
              headers: {
                  Authorization: `Bearer ${this.$state.token}`
              }
          })
          
          //format dates
          setup.data.date = format(fromUnixTime(setup.data.date), 'MM/dd/yyyy')

          //addup labor
        
          setup.data.labor.forEach(labor => {
            labor.total = labor.unit * labor.qty 
          })

          this.totalLabor = setup.data.labor.reduce((a,b)=>numbro(a).add(numbro(b.total)),0)

          this.setup = setup.data

      } catch (err) {
          console.error(err)
      }
    },
    async getJobInfo(){
      
      try {
          let job = await this.$axios.get(`/job/${this.$route.params.jobId}`, {
              headers: {
                  Authorization: `Bearer ${this.$state.token}`
              }
          })
          
          this.job = job.data

      } catch (err) {
          console.error(err)
      }
    },

  },
  created() {
    this.getSetupInfo()
    this.getJobInfo()
  }
}
</script>


<style lang="scss" scoped>
    .material-item {
        padding-top: .25rem;
        padding-bottom: .25rem;
    }

    .logo {
        display: block;
        width: 120px;
        height: 120px;
        margin-bottom: 15px;
    }
    .title {
        font-weight: bold;
        font-size: 2em;
    }
    .instructions-text {
        border: 1px solid #ccc;
        border-radius: .25em;
        padding: .5em .5em 0 .5em;
    }
    .jobName,.instructions,.section {
        font-weight: bold;
        font-size: 1.5em;
    }
    @media print {
       
       @page {

            margin: .5in 0 0 0;
       }
       .instructions, .section{
            color: blue;
        }
    
        
    }
</style>
