
<template>
  <b-container fluid="md" :class="[isMobile ? 'mt-5 pt-3' : 'mt-3' ]">
    <b-row>
      <b-col md="6" order="2" order-md="1">
        <h5>Job: {{job.contractId}} | {{job.customer.fname}} {{job.customer.lname}} </h5>
        Created Date: {{setup.date}} | Last Update: {{setup.updated}} <br/>
        Jobsite: {{job.siteAddress.street}}, {{job.siteAddress.city}}, {{job.siteAddress.state}} {{job.siteAddress.zip}}
      </b-col>
      <b-col md="6" order="1" order-md="2" class="text-right">
        <b-button-group class="mb-2">
          <b-button variant="outline-info" @click="pasteProposalText"><font-awesome-icon icon="fa-solid fa-turn-down" /> Paste Proposal Text</b-button>
          <b-button v-if="!copySetup" variant="outline-danger" @click="delSetupConfirm"><font-awesome-icon icon="fa-regular fa-hand" /> Delete</b-button>
          <b-button v-if="!copySetup" variant="outline-warning" @click="copyJobSetup()"><font-awesome-icon icon="fa-regular fa-copy" /> Copy</b-button>
          <!--<b-button v-if="!copySetup" variant="outline-secondary" disabled><font-awesome-icon icon="fa-regular fa-file-lines" /> Order Sheet</b-button>-->
        </b-button-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="mt-2"><b>Job Instructions:</b></div>
        <setup-editor :instructions="setup.instructions" @editorUpdated="editorUpdated" />
          <b-form-row>

              <b-col cols="6" sm="3">
                <b-form-group id="grpLabel" description="Setup Label" >
                  <b-form-input id="txtLabel" ref="labelTxt" v-model="setup.label" @blur="$refs.labelToolTip.$emit('close')"></b-form-input>
                </b-form-group>
                <b-tooltip target="txtLabel" placement="topright" triggers="manual" variant="warning" ref="labelToolTip">
                  Enter a new label to differentiate setups.
                </b-tooltip>
              </b-col>

              <b-col cols="6" sm="3">
                <b-form-group id="grpMan" description="Manufacturer" >
                  <b-form-input id="txtMan" class="" v-model="setup.manufacturer"></b-form-input>
                </b-form-group>
              </b-col>

              <b-col cols="6" sm="3">
                <b-form-group id="grpMaterial" description="Material" >
                  <b-form-input id="txtMaterial" class="" v-model="setup.material"></b-form-input>
                </b-form-group>
              </b-col>

              <b-col cols="6" sm="3">
                <b-form-group id="grpColor" description="Color" >
                  <b-form-input id="txtColor" class="" v-model="setup.color"></b-form-input>
                </b-form-group>
              </b-col>

          </b-form-row>
        Materials:
        <setup-materials :materials="setup.materials" v-if="setup.materials.length" @updateMaterial="updateMaterial" @updateTax="updateTax" />
        Labor:
        <setup-labor :labor="setup.labor" v-if="setup.labor.length" @updateLabor="updateLabor" />
      </b-col>
    </b-row>

     <b-row no-gutters class="total-row">
        <b-col sm="1">&nbsp;</b-col>
        <b-col sm="7"></b-col>
        <b-col sm="2" class="text-right pt-2">Total Cost</b-col>
        <b-col sm="2" class="text-right total-col">
           <b-form-input disabled class="text-right" :value="numbro(totalCost).format()"></b-form-input>
        </b-col>
      </b-row>

      <b-row no-gutters class="total-row">
        <b-col sm="1">&nbsp;</b-col>
        <b-col sm="7" class="pl-4 pr-4">
          <b-input-group prepend="Sell Price: $" class="w-75">
            <b-form-input class="" v-model="setup.money.sellPrice"></b-form-input>
            <b-input-group-append>
              <b-button variant="outline-success" @click="calc33Percent">Calc 33%</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col sm="2" class="text-right pt-2">Estimated Profit</b-col>
        <b-col sm="2" class="text-right total-col">
           <b-form-input disabled class="text-right" :value="numbro(estimatedProfit).format()"></b-form-input>
        </b-col>
      </b-row>

      <b-row no-gutters class="total-row">
        <b-col sm="1">&nbsp;</b-col>
        <b-col sm="7" class="pl-4">
    
          <b-button variant="primary" @click="saveSetup" class="mr-2"><font-awesome-icon icon="fa-save" ></font-awesome-icon> Save Job Setup</b-button>
          <b-form-checkbox v-model="setup.primary" name="check-button" switch size="lg" class="mt-2 mb-2 d-inline-block">
            <span v-if="setup.primary">This is 'Primary' setup</span>
            <span v-else>Set to 'Primary' setup</span>
          </b-form-checkbox>
          
          
          
          
        </b-col>
        <b-col sm="2" class="text-right pt-2">% of Profit</b-col>
        <b-col sm="2" class="text-right total-col">
           <b-form-input disabled class="text-right" :value="numbro(currentProfitPercent).format()"></b-form-input>
        </b-col>
      </b-row>

      <b-row no-gutters class="footer-row">
        <b-col sm="1">&nbsp;</b-col>
        <b-col sm="7">&nbsp;</b-col>
        <b-col sm="2">&nbsp;</b-col>
        <b-col sm="2">&nbsp;</b-col>
      </b-row>

    
  </b-container>
</template>

<script>
import setupEditor from '../components/jobSetup-components/setupEditor.vue'
import setupMaterials from '../components/jobSetup-components/setupMaterials.vue'
import setupLabor from '../components/jobSetup-components/setupLabor.vue'
import { format, fromUnixTime, getUnixTime } from 'date-fns'
import numbro from 'numbro'


export default {
  name: "jobSetup",
  components: {
    setupEditor,
    setupMaterials,
    setupLabor
  },
  data() {
    return {
      numbro,
      copyThisSetup: false,
      totalMaterial: 0,
      totalLabor: 0,
      totalTax: 0,
      profitPercent: 0,
      setup: {
        primary: false,
        label: "",
        instructions: "",
        materials: [],
        labor: [],
        money: {
          sellPrice: 0,
          profitPercent: 0
        }
      },
      job: {
        customer: {
          fname: "",
          lname: ""
        },
        siteAddress: {
          street: "",
          city: "",
          state: "",
          zip: "" 
        }
      }
    }
  },
  computed: {
        totalCost() {
          return this.totalMaterial + this.totalTax + this.totalLabor
        },
        estimatedProfit() {
          return this.setup.money.sellPrice - this.totalCost
        },
        currentProfitPercent() {
          return (this.estimatedProfit*100)/this.setup.money.sellPrice
        },
        userInfo () {
          return this.$state.userInfo
        },
        id () {
          return this.$route.params.id
        },
        newSetup () {
          return this.$route.params.newSetup ? true : false
        },
        copySetup() {
          return this.$route.params.copySetup ? true : false 
        },
        currentJobTotal () {
          return this.$route.params.jobAmount
        },
        isMobile () {
          return this.$state.isMobile
        }
  },
  methods: {
    updateMaterial(val){
      console.log(`Update Material: Val: ${val}`)
      this.totalMaterial = val
    },
    updateTax(val){
      console.log(`Update Tax: Val: ${val}`)
      this.totalTax = val
    },
    updateLabor(val){
      console.log(`Update labor: Val: ${val}`)
      this.totalLabor = val
    },
    calc33Percent() {
      this.setup.money.sellPrice = parseFloat(this.totalCost * 1.5).toFixed(2)
    },
    copyJobSetup(){
      this.setup.primary = false
      this.setup.label =  ""
      this.copyThisSetup = true
      this.$refs.labelTxt.focus()
      this.$refs.labelToolTip.$emit('open')
    },
    pasteProposalText() {
      if(this.setup.instructions.trim() === ""){
        this.setup.instructions = this.job.scopeText
      } else {
        this.setup.instructions = this.setup.instructions + "<br />" + this.job.scopeText
      }
      
    },
    async saveSetup() {
        
        let payload = {
            job: this.job._id,
            updated: getUnixTime(new Date()),
            label: this.setup.label,
            manufacturer: this.setup.manufacturer,
            material: this.setup.material,
            color: this.setup.color,
            instructions: this.setup.instructions,
            materials: this.setup.materials,
            labor: this.setup.labor,
            money: {
              sellPrice: this.setup.money.sellPrice,
              profitPercent: this.currentProfitPercent
            },
            primary: this.setup.primary ? true : false
        }
        
        //Are we updating an existing setup or creating a new one?
        //if both newSetup & copySetup & copyThisSetup are all false then update this existing setup
        if(!this.newSetup && !this.copySetup && !this.copyThisSetup) { //update an existing setup
          payload._id = this.setup._id
        
          try {
            let res = await this.$axios.put(`/jobsetup/${this.setup._id}`, payload, {
              headers: { 
                Authorization: `Bearer ${this.$state.token}`
              }
            })
            
            console.log(res)
            
            //Log it
            
            let payloadToLog = JSON.stringify(payload ,null,'\t')
            console.log(payloadToLog)
            let logInfo = {
              logMsg : `jobSetup updated by ${this.userInfo.name}`,
              job: this.$route.params.fromJob,
              logUser: this.userInfo.name,
              addInfo: payloadToLog
            }
            this.$actions.log(logInfo)
            
            this.$router.push({ name: 'jobProfile', params: { id : this.$route.params.fromJob } })
            this.$root.$bvToast.toast(`The Jobsetup was saved successfully.`, {
              title: 'Success',
              variant: 'success',
              toaster: 'b-toaster-top-right'
            })
            
          } catch (err) {
            console.error(err)
          }
          
        } else { //save as a new setup
          //set the created date
          payload.date = getUnixTime(new Date())
          
          try {
            let res = await this.$axios.post(`/jobsetup/`, payload, {
              headers: { 
                Authorization: `Bearer ${this.$state.token}`
              }
            })
            
            console.log(res)
            
            //Log it
            
            let payloadToLog = JSON.stringify(payload ,null,'\t')
            let logInfo = {
              logMsg : `A jobSetup was created by ${this.userInfo.name}`,
              job: this.$route.params.fromJob,
              logUser: this.userInfo.name,
              addInfo: payloadToLog
            }
            this.$actions.log(logInfo)
            
            this.$router.push({ name: 'jobProfile', params: { id : this.$route.params.fromJob } })
            this.$root.$bvToast.toast(`A new jobsetup was created successfully.`, {
              title: 'Success',
              variant: 'info',
              toaster: 'b-toaster-top-right'
            })
            
            
          } catch (err) {
            console.error(err)
          }
        }
        //console.log(payload)
    },
    async getSetupInfo(){
      console.log(`Get setup info called.`)

      try {
          let setup = await this.$axios.get(`/jobsetup/${this.id}`, {
              headers: {
                  Authorization: `Bearer ${this.$state.token}`
              }
          })
          
          //format dates
          setup.data.date = format(fromUnixTime(setup.data.date), 'MM/dd/yyyy')
          if (setup.data.updated) {
            setup.data.updated = format(fromUnixTime(setup.data.updated), 'MM/dd/yyyy')
          } 
          
          //set a sellPrice if none exists
          if(setup.data?.money?.sellPrice ){
              console.log(`sellPrice exists!`)
          } else {
              console.log(`sellPrice doesn't exist`)
              console.log(`Try to grab sell price from route...`)
              Object.assign(setup.data, { money: { sellPrice : this.$route.params.jobAmount, profitPercent: 0 } })
          }

          //if this is a copy remove primary value
          if(this.copySetup) { 
            setup.data.primary = false 
          }

          this.setup = setup.data

      } catch (err) {
          console.error(err)
      }
    },
    async getJobInfo(){
      
      try {
          let job = await this.$axios.get(`/job/${this.$route.params.fromJob}`, {
              headers: {
                  Authorization: `Bearer ${this.$state.token}`
              }
          })
          
          this.job = job.data

      } catch (err) {
          console.error(err)
      }
    },
    editorUpdated(val) {
      this.setup.instructions = val
    },
    delSetupConfirm() {
      this.$bvModal.msgBoxConfirm(`Are you sure you want to delete this jobsetup?`, {
        title: "Delete setup?",
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        centered: true
      })
      .then(value => {
        if(value){
          this.delSetup()
        } else {
          return null
        }  
      })
    },
    async delSetup() {
      //Delete It
      try {
          let res = await this.$axios.delete(`/jobsetup/${this.setup._id}`, {
              headers: {
                  Authorization: `Bearer ${this.$state.token}`
              }
          })
          
          console.log(res)
          
          //Log it
          let setupToDelete = JSON.stringify(this.setup ,null,'\t')
          let logInfo = {
            logMsg : `jobSetup deleted by ${this.userInfo.name}`,
            job: this.$route.params.fromJob,
            logUser: this.userInfo.name,
            addInfo: setupToDelete
          }
          this.$actions.log(logInfo)
          
          //route away
          this.$router.push({ name: 'jobProfile', params: { id : this.$route.params.fromJob } })
          this.$root.$bvToast.toast(`Jobsetup was deleted successfully.`, {
            title: 'Success',
            variant: 'warning',
            toaster: 'b-toaster-top-right'
          })
          
          
      } catch (err) {
          console.error(err)
      }
      
    }
  },
  mounted() {
    if(this.isMobile){ 
      window.scrollTo({top:0, left:0, behavior: 'smooth'})
    } 
  },
  created() {
    if(!this.newSetup){
      this.getSetupInfo()
    } else {
      // init materials and labor
      this.setup.materials = [ { qty: 0, desc: null, unit: 0, tax: 0, total: 0, formattedTotal: "0.00" } ]
      this.setup.labor = [{ qty: 0, desc: null, unit: 0, total: 0, formattedTotal: "0.00" } ],
      this.setup.money.sellPrice = this.$route.params.jobAmount
    }
    this.getJobInfo()
  }
}
</script>


<style lang="scss" scoped>

.total-row {
    padding: 4px 6px 4px 0px;
}
.total-col {
    padding: 0 0 0 10px;
}
.footer-row{
  margin-bottom: 50px;
}
</style>
