<template>
    <b-card no-body header-tag="header">
    <template #header>
        <b-form-row no-gutters>
            <!--<b-col sm="1">&nbsp;</b-col>-->
            <b-col cols="6" sm="1">
                <font-awesome-icon icon="fa-solid fa-circle-xmark" class="ml-n3 text-danger" style="width:.7em" @click="editMode = !editMode"/>
               &nbsp;Qty
            </b-col>
            <b-col cols="4" sm="6" >Description</b-col>
            <b-col cols="2" sm="1" class="text-right">No Tax?</b-col>
            <b-col cols="6" sm="2" class="text-right">Unit</b-col>
            <b-col cols="6" sm="2" class="text-right">Total</b-col>
        </b-form-row>
    </template>

    
    <draggable :list="matList" handle=".handle">
        <b-form-row no-gutters v-for="(item, i) in matList" :key="item._id" class="material">
            <b-col cols="3" sm="1" class="mat-col d-flex">
                <b-form-checkbox :id="'deleteChkBox'+i" class="mt-2" v-model="selected" :value="i" v-if="editMode"></b-form-checkbox>
                <font-awesome-icon icon="fa-solid fa-grip-lines-vertical" class="handle" v-if="!editMode"></font-awesome-icon>
                <b-form-input v-model="matList[i].qty" @input="addUpMaterial(i)" class="" ></b-form-input>
            </b-col>
            <b-col cols="9" sm="7" class="mat-col">
                <b-input-group>
                    <b-form-input v-model="matList[i].desc"></b-form-input>
                    <b-input-group-append is-text>
                        <b-form-checkbox :id="'chk'+i" :name="'chk'+i" class="mr-n2" v-model="matList[i].taxable" :value="false" :unchecked-value="true" @input="setTaxable(i)">
                            <span class="">{{matList[i].taxable == false ? "no tax" : ""}}</span>
                        </b-form-checkbox>
                    </b-input-group-append>
                </b-input-group>
            </b-col>
            <b-col cols="6" sm="2" class="mat-col">
                <b-form-input  v-model="matList[i].unit" @input="addUpMaterial(i)" class="text-right"></b-form-input>
            </b-col>
            <b-col cols="6" sm="2">
                <b-form-input v-model="matList[i].formattedTotal" disabled class="text-right"></b-form-input>
            </b-col>
        </b-form-row>
        
        <div slot="footer">
            <b-form-row no-gutters class="total-row">
                <b-col sm="8" class="mat-col">
                    <b-button v-if="editMode" size="small" variant="danger" @click="delRows" class="ml-n3 mr-1"><font-awesome-icon icon="fa-trash" ></font-awesome-icon></b-button>
                    <b-button size="small" variant="outline-primary" @click="addMatRow"><font-awesome-icon icon="fa-plus" ></font-awesome-icon> Add Material</b-button>
                </b-col>
                <b-col sm="2" class="total-col">
                    Total Tax
                </b-col>
                <b-col sm="2">
                    <b-form-input disabled class="text-right" :value="numbro(allMaterialsTotalTax).format()"></b-form-input>
                </b-col>
            </b-form-row>
            
            <b-form-row no-gutters class="total-row">
                <b-col sm="8" class="mat-col">
                    &nbsp;
                </b-col>
                <b-col sm="2" class="total-col">
                    Material Total
                </b-col>
                <b-col sm="2">
                    <b-form-input disabled class="text-right" :value="numbro(allMaterialsTotal).format()"></b-form-input>
                </b-col>
            </b-form-row>

        </div>
    </draggable>
    </b-card>
</template>

<script>
import numbro from "numbro"
import draggable from 'vuedraggable'
import { pullAt } from 'lodash'

export default {
  name: "setup-materials",
  components: {
      draggable
  },
  props: {
      materials: {
          type: Array,
          default: function () {
            return [{qty: 0, desc: "", unit: 0}]
          }
      }
  },
  computed: {
      matList: {
        get() {
            return this.currentMaterials
        },
        set(value) {
            this.currentMaterials = value
        }
    },

  },
  data() {
    return {
        taxRate: 0.0625,
        editMode: false,
        selected: [],
        numbro: numbro,
        allMaterialsTotal: 0,
        allMaterialsTotalTax: 0,
        currentMaterials: [{
            qty: 0,
            desc: "",
            unit: 0
        }]
    }
  },
  methods: {
    addMatRow() {
        let row = {
            qty: 0,
            desc: "",
            total: 0,
            formattedTotal: "0.00",
            tax: 0,
            unit: 0
        }
    
        this.currentMaterials.push(row)

    },
    delRows() {
      let rowsToRemove = this.selected

      this.$bvModal.msgBoxConfirm(`Are you sure you want to delete ${rowsToRemove.length} row(s)?`, {
        title: "Delete Rows",
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        centered: true
      })
      .then(value => {
        if(value){
          console.log(`Deleting Rows: ${rowsToRemove}`)
          pullAt(this.currentMaterials, rowsToRemove)
          this.editMode = false
          this.selected = []
        } else {
          console.log('cancel selected')
          this.editMode = false
          this.selected = []
        }
      })
    },
    addUpMaterial(index) {
        //console.log(`qty: ${this.currentMaterials[index].qty} unit: ${this.currentMaterials[index].unit}`)
        let total = +this.currentMaterials[index].qty * +this.currentMaterials[index].unit
        let formattedTotal = numbro(total).format()
        let tax = 0
        if(this.currentMaterials[index].taxable == false){
            tax = 0
        } else {
            tax = +total * this.taxRate
        } 

        this.currentMaterials[index].total = total
        this.currentMaterials[index].formattedTotal = formattedTotal
        this.currentMaterials[index].tax = tax
        this.materialsTotalTax()
        this.materialsTotal()
        
    },
    materialsTotal () {
        let materialsTotal = this.currentMaterials.reduce((total, material) => {
            return total + material.total
        }, 0)
        materialsTotal = materialsTotal + this.allMaterialsTotalTax
        this.allMaterialsTotal = materialsTotal
        this.$emit("updateMaterial", materialsTotal)
    },
    materialsTotalTax () {
        let totalTax = this.currentMaterials.reduce((total, material) => {
            return total + material.tax
        }, 0)
        this.allMaterialsTotalTax = totalTax
        this.$emit("updateTax", totalTax)
    },
    setTaxable(index) {
        let material = {
            ...this.currentMaterials[index]
        }

        let tax = 0
        if(material.taxable == false){
            tax = 0
        } else {
            tax = +material.total * this.taxRate
        }
        
        material.tax = tax
        
        console.log(material)
        this.currentMaterials.splice(index, 1, material)

    },
    setMaterials() {
        this.currentMaterials = this.materials
        this.currentMaterials.forEach((item, index) => {
            this.addUpMaterial(index)
        })
    }

  },
  created() {
    numbro.setDefaults({
        thousandSeparated: true,
        mantissa: 2
    })

    this.setMaterials()
  },

}
</script>


<style lang="scss" scoped>
.material {
    padding: 0px 6px 4px 7px;
}
.material:first-child {
    padding: 4px 6px 4px 7px;
}
.total-row {
    padding: 0px 6px 4px 20px;
}
.total-row:first-child {
    padding: 5px 6px 4px 20px;
}
.total-col {
    text-align: right;
    padding-top: 5px;
}
.handle {
    color: #CCC;
    margin: 11px 7px 0 0;
}
</style>
