
<template>
    <div class="mt-2">
        <b-button-toolbar v-if="setupEditor">        
            <b-button-group size="sm" class="mr-1 mb-1">
                <b-button variant="outline-secondary" @click="setupEditor.chain().focus().setFontSize('16px').run()" :class="{ 'active': setupEditor.isActive('textStyle', { fontSize: '16px' }) }">
                    <font-awesome-icon icon="paragraph" />
                </b-button>
                <b-button variant="outline-secondary" @click="setupEditor.chain().focus().setFontSize('12px').run()" :class="{ 'active': setupEditor.isActive('textStyle', { fontSize: '12px' }) }">
                    <font-awesome-icon icon="minus" />
                </b-button>
                <b-button variant="outline-secondary" @click="setupEditor.chain().focus().setFontSize('20px').run()" :class="{ 'active': setupEditor.isActive('textStyle', { fontSize: '20px' }) }">
                    <font-awesome-icon icon="plus" />
                </b-button>
            </b-button-group>
            
            <b-button-group size="sm" class="mb-1">
                <b-button variant="outline-secondary" @click="setupEditor.chain().focus().toggleBold().run()" :class="{ 'active': setupEditor.isActive('bold') }">
                    <font-awesome-icon icon="bold" />
                </b-button>
                <b-button variant="outline-secondary" @click="setupEditor.chain().focus().toggleItalic().run()" :class="{ 'active': setupEditor.isActive('italic') }">
                    <font-awesome-icon icon="italic" />
                </b-button>
                <b-button variant="outline-secondary" @click="setupEditor.chain().focus().toggleStrike().run()" :class="{ 'active': setupEditor.isActive('strike') }">
                    <font-awesome-icon icon="strikethrough" />
                </b-button>
                <b-button variant="outline-secondary" @click="setupEditor.chain().focus().toggleUnderline().run()" :class="{ 'active': setupEditor.isActive('underline') }">
                    <font-awesome-icon icon="underline" />
                </b-button>
                <b-button variant="outline-secondary" @click="setupEditor.chain().focus().toggleHighlight().run()" :class="{ 'active': setupEditor.isActive('highlight') }">
                    <font-awesome-icon icon="highlighter" />
                </b-button>
            </b-button-group>

        </b-button-toolbar>

        <div class="editor">
            <editor-content :editor="setupEditor" ref="editor" />
        </div>
    </div>
</template>

<script>

import { Editor, EditorContent} from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Highlight from '@tiptap/extension-highlight'
import Underline from '@tiptap/extension-underline'
import TextStyle from '@tiptap/extension-text-style'
import FontSize from '@luciusa/extension-font-size'

export default {
  name: "jobSetup-editor",
  props: {
      instructions: String
  },
  components: {
    EditorContent
  },
  data() {
    return {
      setupEditor: null,
    }
  },
  watch: {
      instructions(value) {
          const isSame = this.setupEditor.getHTML() === value
          if(isSame) {
              return
          }
          this.setupEditor.commands.setContent(value, false)
      }
  },
  methods: {

  },
  beforeDestroy() {
      this.setupEditor.destroy()
  },
  mounted() {
    this.setupEditor = new Editor({
        content: this.instructions,
        onUpdate: () => {
            // HTML
            //console.log(`Update is: ${this.setupEditor.getHTML()}`)
            let instructionsTxt = this.setupEditor.getHTML()
            this.$emit("editorUpdated", instructionsTxt)
        },
        autoFocus: true,
        extensions: [
            StarterKit,
            Highlight,
            Underline,
            TextStyle,
            FontSize
        ]
    })
      
  }
}
</script>


<style lang="scss" scoped>
.ProseMirror{
    border: 1px solid black;
    border-radius: .2em;
    padding: .5em;
    margin-bottom: 1em;
    max-height: 450px;
    min-height: 350px;
    overflow-x: hidden;
    overflow-y: auto;
    
}
.ProseMirror:focus {
    outline: none;
}
</style>
