<template>
    <b-card no-body header-tag="header">
    <template #header>
        <b-row no-gutters>
            <b-col cols="6" sm="1">
                <font-awesome-icon icon="fa-solid fa-circle-xmark" class="ml-n3 text-danger" style="width:.7em" @click="editMode = !editMode"/>
               &nbsp;Qty
            </b-col>
            <b-col cols="6" sm="7">Description</b-col>
            <b-col cols="6" sm="2" class="text-right">Unit</b-col>
            <b-col cols="6" sm="2" class="text-right">Total</b-col>
        </b-row>
    </template>

    
    <draggable :list="laborList" handle=".handle">  
        <b-form-row no-gutters v-for="(item, i) in laborList" :key="item._id" class="labor">
            <b-col cols="3" sm="1" class="mat-col d-flex">
                <b-form-checkbox :id="'deleteChkBox'+i" class="mt-1" v-model="selected" :value="i" v-if="editMode"></b-form-checkbox>
                <font-awesome-icon icon="fa-solid fa-grip-lines-vertical" class="handle" v-if="!editMode" ></font-awesome-icon>
                <b-form-input v-model="labor[i].qty" @input="addUpLabor(i)"></b-form-input>
            </b-col>
            <b-col cols="9" sm="7" class="mat-col">
                <b-form-input v-model="labor[i].desc"></b-form-input>
            </b-col>
            <b-col cols="6" sm="2" class="mat-col">
                <b-form-input  v-model="labor[i].unit" @input="addUpLabor(i)" class="text-right"></b-form-input>
            </b-col>
            <b-col cols="6" sm="2">
                <b-form-input v-model="labor[i].formattedTotal" disabled class="text-right"></b-form-input>
            </b-col>
        </b-form-row>
        
      <div slot="footer">     
        <b-form-row no-gutters class="total-row">
            <b-col sm="8" class="">
                <b-button v-if="editMode" size="small" variant="danger" @click="delRows" class="ml-n3 mr-1"><font-awesome-icon icon="fa-trash" ></font-awesome-icon></b-button>
                <b-button size="small" variant="outline-primary" @click="addLabRow"><font-awesome-icon icon="fa-plus" ></font-awesome-icon> Add Labor</b-button>
            </b-col>
            <b-col sm="2" class="total-col">
                Labor Total
            </b-col>
            <b-col sm="2">
                <b-form-input disabled class="text-right" :value="numbro(totalLabor).format()"></b-form-input>
            </b-col>
        </b-form-row>
      </div>
    </draggable>
    </b-card>
</template>

<script>
import numbro from "numbro"
import draggable from 'vuedraggable'
import { pullAt } from 'lodash'

export default {
  name: "setup-labor",
  components: {
      draggable
  },
  props: {
      labor: {
          type: Array,
          default: function () {
            return [{qty: 0, desc: "", unit: 0}]
          }
      }
  },
  computed: {
      laborList: {
        get() {
            return this.currentLabor
        },
        set(value) {
            this.currentLabor = value
        }
      }
  },
  data() {
    return {
        numbro: numbro,
        totalLabor: 0,
        selected: [],
        editMode: false,
        currentLabor: [{
            qty: 0,
            desc: "",
            unit: 0
        }]
    }
  },
  methods: {
    addLabRow() {
        let row = {
            qty: 0,
            desc: "",
            total: 0,
            formattedTotal: "0.00",
            unit: 0
        }
    
        this.currentLabor.push(row)
    },
    delRows() {
      let rowsToRemove = this.selected
    
      this.$bvModal.msgBoxConfirm(`Are you sure you want to delete ${rowsToRemove.length} row(s)?`, {
        title: "Delete Rows",
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        centered: true
      })
      .then(value => {
        if(value){
          console.log(`Deleting Rows: ${rowsToRemove}`)
          pullAt(this.currentLabor, rowsToRemove)
          this.editMode = false
          this.selected = []
        } else {
          console.log('cancel selected')
          this.editMode = false
          this.selected = []
        }
      })
    },
    addUpLabor(index) {
        //console.log(`qty: ${this.currentLabor[index].qty} unit: ${this.currentLabor[index].unit}`)
        let total = +this.currentLabor[index].qty * +this.currentLabor[index].unit
        let formattedTotal = numbro(total).format()
        this.currentLabor[index].total = total
        this.currentLabor[index].formattedTotal = formattedTotal
        //console.log(`FormattedTotal: ${formattedTotal}`)
        this.laborTotal()
    },
    laborTotal () {
        let laborTotal = this.currentLabor.reduce((total, lab) => {
            return total + lab.total
        }, 0)
        this.totalLabor = laborTotal
        this.$emit("updateLabor", laborTotal)   
    },
    setLabor() {
        this.currentLabor = this.labor
        this.currentLabor.forEach((item, index) => {
            this.addUpLabor(index)
        })
    }

  },
  created() {
    numbro.setDefaults({
        thousandSeparated: true,
        mantissa: 2
    })
    this.setLabor()
  }
  
}
</script>


<style lang="scss" scoped>
.labor {
    padding: 0px 6px 4px 7px;
}
.labor:first-child {
    padding: 4px 6px 4px 7px;
}
.total-row {
    padding: 0px 6px 4px 20px;
}
.total-row:first-child {
    padding: 5px 6px 4px 20px;
}
.total-col {
    text-align: right;
    padding-top: 5px;
}
.handle {
    color: #CCC;
    margin: 11px 10px 0 0;
}
</style>
